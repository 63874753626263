// normalizers
import slideParseResource from "./parseResource";

/**
 * Normalise la recherche de slides.
 * @param {object} param0
 * @param {object} param0.data
 * @param {Array} param0.data.data
 */
export default function slideParseSearch({ data }) {
  return {
    data: (data?.data ?? []).map((slide) =>
      slideParseResource({
        data: slide,
      })
    ),
  };
}
