/**
 * Normalise un slide.
 * @param {object} param0
 * @param {object} param0.data
 * @param {string} param0.data.id
 * @param {string} param0.data.alias
 * @param {string} param0.data.title
 * @param {string} param0.data.subtitle
 * @param {string} param0.data.target
 * @param {object} param0.data.metas.coverUrls
 * @param {string} param0.data.metas.coverUrls.mini
 * @param {string} param0.data.metas.coverUrls.small
 * @param {string} param0.data.metas.coverUrls.large
 */
export default function slideParseResource({ data }) {
  return {
    id: data?.id,
    alias: data?.alias,
    title: data?.title,
    subtitle: data?.subtitle,
    coverUrls: data?.metas?.coverUrls,
    target: data?.target,
  };
}
