// dependencies
import React from "react";
import {
  View,
  ImageSourcePropType,
  StyleSheet,
  ViewStyle,
  TextStyle,
  AccessibilityRole,
} from "react-native";

// components
import Image from "../Image";
import Heading from "../Heading";
import Stack from "../Stack";
import Link from "../Link";
import BaseComponent from "../BaseComponent";

type IProps = {
  title: string;
  to: string;
  subtitle?: string;
  imageSource: ImageSourcePropType;
};

type IState = {};

export const IMAGE_RATIO = 3 / 4;

class ContentMosaic extends React.PureComponent<IProps, IState> {
  public static defaultProps: Partial<IProps>;

  private getStyles(): StyleSheet.NamedStyles<{
    view: ViewStyle;
    link: TextStyle;
  }> {
    return StyleSheet.create({
      view: {
        width: "100%",
      },
      link: {
        display: "flex",
      },
    });
  }

  public render(): JSX.Element {
    const { title, subtitle, imageSource, to } = this.props;

    const styles = this.getStyles();

    return (
      <BaseComponent accessibilityRole={"article" as AccessibilityRole}>
        <Link style={styles.link} to={to}>
          <View style={styles.view}>
            <Image
              accessibilityLabel={title}
              ratio={IMAGE_RATIO}
              source={imageSource}
              alt={title}
            />

            <Stack marginTop={0.3125} />

            <Heading
              numberOfLines={1}
              level={3}
              size={"md"}
              textStyle={{ fontWeight: "500" }}
            >
              {title}
            </Heading>

            {null != subtitle && (
              <Heading numberOfLines={1} level={4} size={"sm"}>
                {subtitle}
              </Heading>
            )}
          </View>
        </Link>
      </BaseComponent>
    );
  }
}

export default ContentMosaic;
