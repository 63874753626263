// libraries
import { Router } from "../../libraries/Router";
import Uri from "../../libraries/Uri";

const mediasUri = new Uri(
  process.env.NEXT_PUBLIC_MEDIAS_SCHEME,
  undefined,
  process.env.NEXT_PUBLIC_MEDIAS_HOSTNAME
);

const mediasRouter = new Router(mediasUri);

mediasRouter
  .get(":path")
  .setName("home")
  .setConstrain("path", /[A-z0-9-./]+/);

export default mediasRouter;
