// libraries
import { generateUri } from "@cloudspire/legacy-shared/src/libraries";

// constants
import { apiRouter } from "@cloudspire/legacy-shared/src/constants/router";

// normalizers
import { pricingEstimateParseSearch } from "../normalizers/pricing/estimate";

// constants
import { BOOKING_WORKFLOW_VALUE_NATIVE } from "../constants/booking";
import { BOOKING_SOURCE_VALUE_WEB } from "@cloudspire/shared/constants/booking";

export function pricingEstimateRoomPage({ roomReferenceList, channelId }) {
  return {
    key: roomReferenceList
      ? generateUri({
          router: apiRouter,
          name: "Api.Alfred.Room.Pricing.Estimate",
          query: {
            room_id: roomReferenceList,
            channelId,
            source: BOOKING_SOURCE_VALUE_WEB,
            workflow: BOOKING_WORKFLOW_VALUE_NATIVE,
            adults: 1,
          },
        })
      : null,
    normalizer: pricingEstimateParseSearch,
  };
}

export function pricingEstimateAside({ roomReference, channelId }) {
  return {
    key: roomReference
      ? generateUri({
          router: apiRouter,
          name: "Api.Alfred.Room.Pricing.Estimate",
          query: {
            room_id: [roomReference],
            channelId,
            source: BOOKING_SOURCE_VALUE_WEB,
            workflow: BOOKING_WORKFLOW_VALUE_NATIVE,
            adults: 1,
          },
        })
      : null,
    normalizer: pricingEstimateParseSearch,
  };
}

export function pricingEstimateContentCampaignContainer({
  roomReferenceList,
  configuration,
}) {
  return {
    key: roomReferenceList
      ? generateUri({
          router: apiRouter,
          name: "Api.Alfred.Room.Pricing.Estimate",
          query: {
            room_id: roomReferenceList,
            channelId: configuration.channel.id,
            source: BOOKING_SOURCE_VALUE_WEB,
            workflow: BOOKING_WORKFLOW_VALUE_NATIVE,
            adults: 1,
          },
        })
      : null,
    normalizer: pricingEstimateParseSearch,
  };
}
