//deps
import React from "react";
import { View, StyleSheet, ViewStyle, StyleProp } from "react-native";

// hooks
import useViewportMedia from "../../hocs/useViewportMedia";

// libraries
import { emToPx } from "@cloudspire/legacy-resources/src/libraries";

export type IProps = {
  style?: StyleProp<ViewStyle>;
};

const styles = StyleSheet.create({
  view: {
    backgroundColor: "#ffffff",
    padding: emToPx(1.5),
  },
  view__desktop: {
    shadowRadius: 12,
    shadowBorder: 12,
    borderRadius: 12,
  },
});

const SearchHomeBox: React.FunctionComponent<IProps> = React.memo(
  function SearchHomeBox({ children, style, ...attrs }) {
    const { matches } = useViewportMedia({ queries: [{ minWidth: 800 }] });

    return (
      <View
        {...attrs}
        style={[styles.view, style, matches && styles.view__desktop]}
      >
        {children}
      </View>
    );
  }
);

export default SearchHomeBox;
