// deps
import React from "react";
import { css, StyleSheet } from "aphrodite";
import { useIntl } from "react-intl";

// components
import Image from "@cloudspire/legacy-resources/src/components/Image2";
import Heading from "@cloudspire/legacy-resources/src/components/Heading2";
import Text from "@cloudspire/legacy-resources/src/components/Text2";
import Button from "@cloudspire/legacy-resources/src/components/Button2";
import Stack from "@cloudspire/legacy-resources/src/components/Stack";
import Media from "@cloudspire/legacy-resources/src/components/Media";

// contexts
import { useConfiguration } from "@cloudspire/legacy-resources/src/contexts/configuration";
import { useRouters } from "@cloudspire/legacy-resources/src/contexts/routers";

// libraries
import { emToPx } from "@cloudspire/legacy-resources/src/libraries";
import { generateUri } from "@cloudspire/legacy-shared/src/libraries";

// helpers
import { contentGenerateUriRelayOnType } from "@cloudspire/legacy-resources/src/helpers/content";

// constants
import mediasRouter from "@cloudspire/legacy-shared/src/constants/router/mediasRouter";

type PropsType = {
  content: any;
};

const styles = StyleSheet.create({
  component: {
    paddingTop: emToPx(1),
    paddingBottom: emToPx(1),
  },
  row: {
    display: "flex",
  },
  row__tablet: {
    flexDirection: "column",
  },
  row__mobile: {
    flexDirection: "column",
  },
  col__image: {
    minWidth: emToPx(43.15),
    minHeight: emToPx(12.5),
  },
  col__image__tablet: {
    minWidth: 0,
  },
  col__image__mobile: {
    minWidth: null,
    width: "100%",
    paddingBottom: emToPx(6.25),
  },
  image: {
    maxWidth: emToPx(33.75),
    position: "relative",
  },
  image__mobile: {
    maxWidth: null,
  },
  logo: {
    position: "absolute",
    right: 0,
    top: 0,
    width: 200,
    height: 200,
    transform: `translate3d(calc(100% - 3.125rem), 1rem, 0)`,
  },
  logo__tablet: {},
  logo__mobile: {
    bottom: 0,
    top: null,
    right: "50%",
    transform: `translate3d(50%, 50%, 0)`,
  },
  col__description: {
    marginLeft: emToPx(3.75),
  },
  col__description__tablet: {
    marginLeft: 0,
    marginTop: emToPx(1.25),
  },
  col__description__mobile: {
    marginLeft: 0,
    marginTop: emToPx(1.25),
  },
  heading: {
    fontSize: emToPx(1.625),
    marginBottom: emToPx(1.5),
  },
  inlineBlock: {
    display: "inline-block",
  },
  coverFallback: {
    width: "100%",
    height: emToPx(14),
    borderStyle: "solid",
    borderColor: "#e5e5e5",
    borderWidth: emToPx(0.0625),
    borderRadius: emToPx(0.25),
  },
});

function RejoindreHomepage(props: PropsType) {
  const { content } = props;

  const intl = useIntl();

  const configuration = useConfiguration();

  const { frontRouter } = useRouters();

  return (
    <Media
      query={{
        maxWidth: 1100,
        minWidth: 701,
      }}
    >
      {(isTablet) => {
        return (
          <Media query={{ maxWidth: 700 }}>
            {(isMobile) => {
              return (
                <div className={css(styles.component)}>
                  <div
                    className={css(
                      styles.row,
                      isTablet && styles.row__tablet,
                      isMobile && styles.row__mobile
                    )}
                  >
                    <div
                      className={css(
                        styles.col__image,
                        isTablet && styles.col__image__tablet,
                        isMobile && styles.col__image__mobile
                      )}
                    >
                      <div
                        className={css(
                          styles.image,
                          isTablet && styles.image__tablet,
                          isMobile && styles.image__mobile
                        )}
                      >
                        {content?.coverUrls?.large ? (
                          <Image
                            source={{ uri: content?.coverUrls?.large }}
                            style={{
                              maxHeight: emToPx(21.0625),
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          <div className={css(styles.coverFallback)} />
                        )}
                        <div
                          className={css(
                            styles.logo,
                            isTablet && styles.logo__tablet,
                            isMobile && styles.logo__mobile
                          )}
                        >
                          <Image
                            source={{
                              uri: generateUri({
                                router: mediasRouter,
                                name: "home",
                                parameters: {
                                  path: `/assets/rejoindre/${configuration.channel.realm.reference}/logo.png`,
                                },
                              }),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className={css(
                        styles.col__description,
                        isTablet && styles.col__description__tablet,
                        isMobile && styles.col__description__mobile
                      )}
                    >
                      <Heading level={3} aStyle={styles.heading}>
                        {content?.title}
                      </Heading>

                      <Stack marginTop={1.5} />

                      <Text>{content?.about}</Text>

                      <Stack marginTop={1.5} />

                      <span className={css(styles.inlineBlock)}>
                        <Button
                          appearance="secondary"
                          label={intl.formatMessage({
                            id: "front.containers.rejoindre.homepage.button.label",
                          })}
                          to={contentGenerateUriRelayOnType({
                            intl,
                            frontRouter,
                            content,
                          })}
                        >
                          {intl.formatMessage({
                            id: "front.containers.rejoindre.homepage.button.label",
                          })}
                        </Button>
                      </span>
                    </div>
                  </div>
                </div>
              );
            }}
          </Media>
        );
      }}
    </Media>
  );
}

export default RejoindreHomepage;
