/**
 * Version simplifiée.
 * Ce module n'est pas exporté par React Native.
 * @see https://github.com/necolas/react-native-web/blob/master/packages/react-native-web/src/modules/normalizeColor/index.js
 */
export default (color: number, opacity = 1) => {
  if (color == null) {
    return;
  }

  if (color != null) {
    const r = (color >> 16) & 255;
    const g = (color >> 8) & 255;
    const b = color & 255;
    const a = ((color >> 24) & 255) / 255;
    const alpha = (a * opacity).toFixed(2);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
};
