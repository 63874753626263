// dependencies
import React from "react";
import { View, StyleSheet } from "react-native";

// components
import UIContainer from "@cloudspire/legacy-resources/src/components/UIContainer";
import Heading from "@cloudspire/legacy-resources/src/components/Heading";
import Stack from "@cloudspire/legacy-resources/src/components/Stack";

// libraries
import { emToPx } from "@cloudspire/legacy-resources/src/libraries";

const styles = StyleSheet.create({
  contentSection: {
    paddingTop: emToPx(1),
    paddingBottom: emToPx(1),
  },
  headingWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  heading: {
    maxWidth: "100%",
  },
});

type PropsType = {
  title: string;
  subtitle: string;
  addon: React.ReactNode;
  color: string;
  useTitle: boolean;
};

const ContentSection: React.FunctionComponent<PropsType> = (props) => {
  const { title, subtitle, addon, useTitle, color, children } = props;

  return (
    <>
      <View
        accessibilityRole="section"
        style={[
          styles.contentSection,
          {
            backgroundColor: color,
          },
        ]}
      >
        <UIContainer isFullWidth={true}>
          {useTitle && undefined !== title && (
            <>
              <View style={styles.headingWrapper}>
                <View style={styles.heading}>
                  <Heading
                    level={2}
                    size={"lg"}
                    textStyle={{ fontWeight: "500" }}
                  >
                    {title}
                  </Heading>

                  {subtitle && (
                    <>
                      <Heading level={3} size={"sm"}>
                        {subtitle}
                      </Heading>
                    </>
                  )}

                  <Stack marginTop={1} />
                </View>

                {undefined !== addon && addon}
              </View>
            </>
          )}

          {children}
        </UIContainer>
      </View>
    </>
  );
};

export default ContentSection;
