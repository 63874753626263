// dependencies
import React from "react";

// components
import BaseComponent from "../BaseComponent";
import FlexEmbed from "../FlexEmbed";
import Stack from "../Stack";
import PlaceholderView from "../PlaceholderView";

// constants
import { IMAGE_RATIO } from "./ContentMosaic";

type IProps = {};

type IState = {};

class Placeholder extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps>;

  public render(): JSX.Element {
    return (
      <BaseComponent>
        <FlexEmbed ratio={IMAGE_RATIO}>
          <PlaceholderView style={{ width: "100%", height: "100%" }} />
        </FlexEmbed>

        <Stack marginTop={0.3125} />

        <PlaceholderView style={{ width: "60%", height: "1.875em" }} />

        <Stack marginTop={0.3125} />

        <PlaceholderView style={{ width: "60%", height: "1.1875em" }} />
      </BaseComponent>
    );
  }
}

export default Placeholder;
