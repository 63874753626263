// dependencies
import React from "react";
import { SWRConfig } from "swr";

// api
import { slideHome } from "@cloudspire/legacy-resources/src/api/slide";

// libraries
import { fetchFallback } from "@cloudspire/legacy-resources/src/libraries/utils/fetcher";

// containers
import IndexContainer from "../containers/Index";

export default function IndexPage(props) {
  const { fallback } = props;

  return (
    <SWRConfig value={{ fallback }}>
      <IndexContainer />
    </SWRConfig>
  );
}

IndexPage.getInitialProps = async function (ctx) {
  const { isomorphicFetcher } = ctx;

  const [slideSearchFallback] = await fetchFallback({
    ...slideHome(),
    fetcher: isomorphicFetcher,
  });

  return {
    fallback: {
      ...slideSearchFallback,
    },
  };
};
