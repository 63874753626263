// dependencies
import React from "react";
import {
  Animated,
  StyleSheet,
  Easing,
  ViewStyle,
  ViewProps,
  processColor,
} from "react-native";

// libraries
import normalizeColor from "../../libraries/utils/normalizeColor";

// theming
import theming, { IPropsWithTheme } from "../../constants/theming";

const { withTheme } = theming;

type IProps = {} & ViewProps & IPropsWithTheme;

type IState = {};

const DURATION = 1600;

class PlaceholderView extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps>;

  private animatedValue: Animated.Value;

  public constructor(props: IProps) {
    super(props);

    this.animatedValue = new Animated.Value(0);
  }

  public componentDidMount(): void {
    this.runAnimation();
  }

  private runAnimation(): void {
    Animated.loop(
      Animated.sequence([
        Animated.timing(this.animatedValue, {
          toValue: 1,
          duration: DURATION,
          easing: Easing.ease,
          useNativeDriver: false,
        }),
        Animated.timing(this.animatedValue, {
          toValue: 0,
          duration: DURATION,
          easing: Easing.ease,
          useNativeDriver: false,
        }),
      ])
    ).start();
  }

  private getStyles(): StyleSheet.NamedStyles<{
    placeholderView: ViewStyle;
  }> {
    const { theme } = this.props;

    // récupère la valeur numérique de la couleur
    const color = processColor(theme.PRIMARY_COLOR);

    return StyleSheet.create({
      placeholderView: {
        backgroundColor: this.animatedValue.interpolate({
          inputRange: [0, 1],
          outputRange: [normalizeColor(color, 0.1), normalizeColor(color, 0.4)],
        }) as unknown as string,
      },
    });
  }

  public render(): JSX.Element {
    const { style, ...attrs } = this.props;

    const styles = this.getStyles();

    return <Animated.View {...attrs} style={[styles.placeholderView, style]} />;
  }
}

export default withTheme(PlaceholderView);
