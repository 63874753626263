// dependencies
import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from "react";
import { StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
  image: {
    display: "block",
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  image__notResolved: {
    display: "none",
  },
  pending: {
    display: "block",
    width: "100%",
    height: "100%",
  },
  fallback: {
    display: "block",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
});

type PropsType = {
  source: {
    uri: string;
  };
};

const Image: React.FunctionComponent<PropsType> = (props) => {
  const { source, ...otherProps } = props;

  const [state, setState] = useState<"pending" | "resolved" | "rejected">(
    "pending"
  );

  const handleLoad = useCallback(() => {
    setState("resolved");
  }, []);

  const handleError = useCallback(() => {
    setState("rejected");
  }, []);

  const color = "#abc5b9";
  const fadeColor = "#eaf0ee";

  const dynamicStyles = useMemo(() => {
    const blinkKeyframes = {
      "0%": {
        backgroundColor: color,
      },

      "50%": {
        backgroundColor: fadeColor,
      },

      "100%": {
        backgroundColor: color,
      },
    };

    return StyleSheet.create({
      pending: {
        animationName: [blinkKeyframes],
        animationDuration: "1000ms",
        animationIterationCount: "infinite",
      },
    });
  }, [color, fadeColor]);

  const $img = useRef<HTMLImageElement>();

  useEffect(
    function () {
      if ("pending" === state && $img.current instanceof HTMLImageElement) {
        if ($img.current.complete) {
          setState("resolved");
        }
      }
    },
    [state]
  );

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/alt-text, @next/next/no-img-element */}
      <img
        ref={$img}
        className={css(
          styles.image,
          "resolved" !== state && styles.image__notResolved
        )}
        src={source.uri}
        onLoad={handleLoad}
        onError={handleError}
        {...otherProps}
      />
      {"pending" === state && (
        <div className={css(styles.pending, dynamicStyles.pending)} />
      )}
      {"rejected" === state && (
        // eslint-disable-next-line @next/next/no-img-element
        <img
          className={css(styles.fallback)}
          src="/images/fallback.png"
          alt="Erreur"
        />
      )}
    </>
  );
};

export default Image;
